export default {
    setup() {
        return {  };
    },
    data() {
        return {
            username: '',
            password: '',
            radio: '',
        }
    },
    methods: {
        // 查看订单
        goOrderPageFunc() {
            this.$router.push({
                path: '/orderDetail'
            })
        },
        onSubmit() {

        }
    },
}