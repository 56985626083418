<template>
  <div class="pays">
    <div class="flex flex-center flex-wrap pays-icon">
      <img src="../../../assets/img/order/pay_error.png" alt="">
      <div class="pays-icon-title">支付失败</div>
    </div>
    <div class="flex flex-between pays-btn">
      <van-button class="pays-btn-list" plain round color="#E62129" @click="goOrderPageFunc">查看订单</van-button>
      <van-button class="pays-btn-list" round color="#E62129" @click="goOrderPageFunc">继续支付</van-button>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>